import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import intlTelInput from 'intl-tel-input';
import { fr } from 'intl-tel-input/i18n';
import { NotificationService } from '../../core/core.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lpa-phone-number-dialog',
  templateUrl: './phone-number-dialog.component.html',
  styleUrls: ['phone-number-dialog.component.scss']
})
export class PhoneNumberDialogComponent implements AfterViewInit {
  phoneNumber: string = '';
  phoneInput: any;
  input: HTMLInputElement;
  dialogForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PhoneNumberDialogComponent>,
    private notificationsService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.createForm();
  }

  createForm() {
    this.dialogForm = this.fb.group({
      perso_addr: ['', Validators.required],
      perso_addr_2: [''],
      pro_addr: [''],
      pro_addr_2: [''],
      postal_code: [''],
      perso_postal_code: ['', Validators.required],
      city: [''],
      perso_city: ['', Validators.required],
      vat_number: [''],
      pro_name: ['']
    });
  }

  ngAfterViewInit(): void {
    const input = document.querySelector('#phone-dialog') as HTMLInputElement;

    const phoneInput = intlTelInput(input, {
      i18n: fr,
      strictMode: true,
      nationalMode: true,
      initialCountry: 'auto',
      geoIpLookup: function(callback) {
        fetch('https://ipinfo.io/json', {
          headers: {
            Accept: 'application/json'
          }
        })
          .then(resp => resp.json())
          .then(resp => {
            var countryCodeMobile = resp && resp.country ? resp.country : 'fr';
            callback(countryCodeMobile);
          });
      },
      utilsScript:
        'https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js'
    });

    //input.addEventListener("countrychange", function() {
    //input.value="";
    //});
    this.input = input;
    this.phoneInput = phoneInput;

    this.dialogForm.patchValue(this.data.profileEditionForm.value);
    this.cdr.detectChanges();

    this.input.addEventListener('input', () => {
      // Remove all occurrences of '+' and '.' from the input value
      this.input.value = this.input.value.replace(/[+.]/g, '');
    });

    phoneInput.promise.then(() => {
      if (this.data.phone) {
        this.phoneInput.setNumber(
          this.data.codeCountry
            ? `${this.data.codeCountry}${this.data.phone}`
            : this.data.phone
        );
      }
      this.cdr.detectChanges();

      if (!this.phoneInput.isValidNumberPrecise()) {
        this.input.value = '';
        this.cdr.detectChanges();
      }
    });
  }

  Verify(): void {
    if (this.phoneInput.isValidNumberPrecise()) {
      if (this.dialogForm.valid) {
        this.data.profileEditionForm.patchValue(this.dialogForm.value);
        this.data.onSave(this.phoneInput, this.input);
        this.dialogRef.close();
      } else {
        this.notificationsService.error('Remplissez tous les champs');
      }
    } else {
      this.notificationsService.error('Numéro de téléphone invalide');
    }
  }
}
